import React from 'react';
import { DrawTable } from '../tables';
import { aspects, functions, reinins } from '../../socioData';
import data from '../../jsons/simple';
import { contentType } from "../../Const";

const SimplePage = () => {
  // Tables for different content types
  const AspectTable = (
    <div>
      <h5 className="mx-3">Ознаки Аспектів</h5>
      <DrawTable 
        data={data.aspects} 
        idx={0} 
        dichotomies={aspects} 
        type={contentType.aspects} 
        key="aspects"
        showId={false}
      />
    </div>
  );

  const FunctionTable = (
    <div>
      <h5 className="mx-3">Ознаки Функцій</h5>
      <DrawTable 
        data={data.functions} 
        idx={0} 
        dichotomies={functions} 
        type={contentType.functions} 
        key="functions"
        showId={false}
      />
    </div>
  );

  const ReininTable = (
    <div>
      <h5 className="mx-3">Ознаки Рейніна</h5>
      <DrawTable 
        data={data.reinins} 
        idx={0} 
        dichotomies={reinins} 
        type={contentType.reinins} 
        key="reinins"
        showId={false}
      />
    </div>
  );

  return (
    <div>
      <div className="d-flex">
        {AspectTable}
        {FunctionTable}
      </div>
      <div className="d-flex">
        {ReininTable}
      </div>
    </div>
  );
};

export default SimplePage;