import { contentList, contentType, log } from '../../Const';

// Memoization caches
const base3Cache = new Map();
const base4Cache = new Map();

/**
 * Finds matching dichotomy index for a value array
 * @param {Array} dichotomies - Array of dichotomies
 * @param {Array} valueArray - Array of values to match
 * @returns {Number} - Index of matching dichotomy or -1 if not found
 */
const findDichotomyIndex = (dichotomies, valueArray) => {
  return dichotomies.findIndex(d => JSON.stringify(d.value) === JSON.stringify(valueArray));
};

/**
 * Generate a cache key for calculations
 * @param {Array} dichotomies - Array of dichotomies
 * @param {String} list - Type of content list to filter by
 * @param {String} type - Type of content
 * @returns {String} - Cache key
 */
const generateCacheKey = (dichotomies, list, type) => {
  // Create a hash from the dichotomies array
  const dichotomiesHash = dichotomies
    .map(d => d.name)
    .sort()
    .join('_');
  
  return `${type}_${list}_${dichotomiesHash}`;
};

/**
 * Calculates base 3 data (used for aspects and functions)
 * @param {Array} dichotomies - Array of dichotomies
 * @param {String} list - Type of content list to filter by
 * @param {String} type - Type of content
 * @returns {Array} - Calculated basis data
 */
export const calculateBase3 = (dichotomies, list, type) => {
  if (log) console.log(`Calculating base 3 for ${type}, list: ${list}`);
  
  // Check cache first
  const cacheKey = generateCacheKey(dichotomies, list, type);
  if (base3Cache.has(cacheKey)) {
    if (log) console.log("Using memoized base3 calculation");
    return base3Cache.get(cacheKey);
  }
  
  // Generate basis list combinations
  const basisList = [];
  dichotomies.forEach((x1, idx1) => {
    dichotomies.forEach((x2, idx2) => {
      dichotomies.forEach((x3, idx3) => {
        // Skip if any indices are the same (total possible: 7*7*7=343)
        if (idx1 === idx2 || idx1 === idx3 || idx2 === idx3) {
          if (log) console.log("skip ", x1.name, x2.name, x3.name);
        } else {
          // Add index property (total valid: 7*6*5=210)
          const item1 = { ...x1, idx: idx1 };
          const item2 = { ...x2, idx: idx2 };
          const item3 = { ...x3, idx: idx3 };
          
          // Check if this combination already exists in some order
          let found = false;
          basisList.forEach(group => {
            if (group.findIndex(x => x.idx === item1.idx) >= 0 &&
                group.findIndex(x => x.idx === item2.idx) >= 0 &&
                group.findIndex(x => x.idx === item3.idx) >= 0) {
              found = true;
            }
          });
          
          // If unique, add to list (total unique: 35)
          if (!found) {
            const basis = [item1, item2, item3];
            basisList.push(basis);
          }
        }
      });
    });
  });

  if (log) {
    console.log("basis variants:", basisList.map(e => e.map(x => x.name)));
  }

  // Process each basis combination
  const basisData = [];
  basisList.forEach(group => {
    const x1 = group[0].value;
    const x2 = group[1].value;
    const x3 = group[2].value;
    const x12 = [];
    const x13 = [];
    const x23 = [];
    const x123 = [];

    // Calculate derived values using NXOR
    for (let i = 0; i < 8; i++) {
      x12.push(+(x1[i] === x2[i]));
      x13.push(+(x1[i] === x3[i]));
      x23.push(+(x2[i] === x3[i]));
      x123.push(+(x1[i] ^ x2[i] ^ x3[i]));
    }

    // Validation checks
    let corruptedDuplicate = false;
    let corruptedSplit = false;
    const propsList = [x1, x2, x3, x12, x13, x23, x123];
    
    // Check for proper value distribution
    propsList.forEach(x => {
      const value = x.reduce((a, b) => a + b, 0);
      if (value !== 4) {
        corruptedSplit = true;
      }
    });

    // Check for duplicates
    for (let j = 0; j < 7; j++) {
      for (let k = 0; k < 7; k++) {
        if (j !== k && JSON.stringify(propsList[j]) === JSON.stringify(propsList[k])) {
          corruptedDuplicate = true;
        }
      }
    }

    if (log) {
      console.log(
        group.map(g => g.name),
        corruptedSplit ? "Bad split!" : "",
        corruptedDuplicate ? "Duplicates!" : ""
      );
      console.log(x1, x2, x3);
      console.log(x12, x13, x23, x123);
    }

    // Add to result based on list type
    if ((list === contentList.all) ||
        (list === contentList.correct && !corruptedSplit && !corruptedDuplicate) ||
        (list === contentList.wrong && (corruptedSplit || corruptedDuplicate))) {
      
      basisData.push({
        x1: group[0].idx,
        x2: group[1].idx,
        x3: group[2].idx,
        x12: findDichotomyIndex(dichotomies, x12),
        x13: findDichotomyIndex(dichotomies, x13),
        x23: findDichotomyIndex(dichotomies, x23),
        x123: findDichotomyIndex(dichotomies, x123)
      });
    }
  });

  // Store in cache before returning
  base3Cache.set(cacheKey, basisData);
  
  return basisData;
};

/**
 * Prepares item data for base 4 calculation
 * @param {Array} group - Group of dichotomies
 * @param {Array} dichotomies - All dichotomies
 * @param {Object} varsList - Object with calculated variables
 * @returns {Object} - Prepared item
 */
const getItemToAdd = (group, dichotomies, varsList) => {
  const { 
    x1, x2, x3, x4, x12, x13, x23, x14, 
    x24, x34, x123, x124, x134, x234, x1234 
  } = varsList;
  
  try {
    return {
      x1: group[0].idx,
      x2: group[1].idx,
      x3: group[2].idx,
      x4: group[3].idx,
      x12: findDichotomyIndex(dichotomies, x12),
      x13: findDichotomyIndex(dichotomies, x13),
      x23: findDichotomyIndex(dichotomies, x23),
      x14: findDichotomyIndex(dichotomies, x14),
      x24: findDichotomyIndex(dichotomies, x24),
      x34: findDichotomyIndex(dichotomies, x34),
      x123: findDichotomyIndex(dichotomies, x123),
      x124: findDichotomyIndex(dichotomies, x124),
      x134: findDichotomyIndex(dichotomies, x134),
      x234: findDichotomyIndex(dichotomies, x234),
      x1234: findDichotomyIndex(dichotomies, x1234)
    };
  } catch (e) {
    return {
      x1: group[0].idx,
      x2: group[1].idx,
      x3: group[2].idx,
      x4: group[3].idx,
      error: true
    };
  }
};

/**
 * Calculates base 4 data (used for reinins)
 * @param {Array} dichotomies - Array of dichotomies
 * @param {String} list - Type of content list to filter by
 * @param {String} type - Type of content
 * @returns {Array} - Calculated basis data
 */
export const calculateBase4 = (dichotomies, list, type) => {
  if (log) console.log(`Calculating base 4 for ${type}, list: ${list}`);

  // Check cache first
  const cacheKey = generateCacheKey(dichotomies, list, type);
  if (base4Cache.has(cacheKey)) {
    if (log) console.log("Using memoized base4 calculation");
    return base4Cache.get(cacheKey);
  }
  
  // Generate basis list combinations
  const basisList = [];
  dichotomies.forEach((x1, idx1) => {
    dichotomies.forEach((x2, idx2) => {
      dichotomies.forEach((x3, idx3) => {
        dichotomies.forEach((x4, idx4) => {
          // Skip if any names are duplicated
          const nameList = [x1.name, x2.name, x3.name, x4.name];
          const hasDuplicates = [x1.name, x2.name, x3.name, x4.name].some(name => 
            nameList.filter(n => n === name).length > 1
          );
          
          if (hasDuplicates) {
            if (log) console.log("skip", x1.name, x2.name, x3.name, x4.name);
          } else {
            // Add index property
            const item1 = { ...x1, idx: idx1 };
            const item2 = { ...x2, idx: idx2 };
            const item3 = { ...x3, idx: idx3 };
            const item4 = { ...x4, idx: idx4 };
            
            // Check if this combination already exists in some order
            let found = false;
            basisList.forEach(group => {
              if (group.findIndex(x => x.idx === item1.idx) >= 0 &&
                  group.findIndex(x => x.idx === item2.idx) >= 0 &&
                  group.findIndex(x => x.idx === item3.idx) >= 0 &&
                  group.findIndex(x => x.idx === item4.idx) >= 0) {
                found = true;
              }
            });
            
            // If unique, add to list
            if (!found) {
              const basis = [item1, item2, item3, item4];
              basisList.push(basis);
            }
          }
        });
      });
    });
  });

  if (log) {
    console.log("basis variants count:", basisList.length);
    console.log("basis variants:", basisList.map(e => e.map(x => x.name)));
  }

  // Process each basis combination
  const basisData = [];
  basisList.forEach(group => {
    const x1 = group[0].value;
    const x2 = group[1].value;
    const x3 = group[2].value;
    const x4 = group[3].value;
    
    // Calculate all derived arrays
    const x12 = [];
    const x13 = [];
    const x23 = [];
    const x14 = [];
    const x24 = [];
    const x34 = [];
    const x123 = [];
    const x124 = [];
    const x134 = [];
    const x234 = [];
    const x1234 = [];

    for (let i = 0; i < 16; i++) {
      // kvadr
      x12.push(+(x1[i] === x2[i]));
      x13.push(+(x1[i] === x3[i]));
      x23.push(+(x2[i] === x3[i]));
      // dual
      x14.push(+(x1[i] === x4[i]));
      x24.push(+(x2[i] === x4[i]));
      x34.push(+(x3[i] === x4[i]));
      // ind
      x123.push(+(x1[i] ^ x2[i] ^ x3[i]));
      x124.push(+(x1[i] ^ x2[i] ^ x4[i]));
      x134.push(+(x1[i] ^ x3[i] ^ x4[i]));
      x234.push(+(x2[i] ^ x3[i] ^ x4[i]));
      // ring
      x1234.push(+!(x1[i] ^ x2[i] ^ x3[i] ^ x4[i]));
    }

    // Validation checks
    let corruptedDuplicate = false;
    let corruptedSplit = false;
    const propsList = [
      x1, x2, x3, x4, x12, x13, x23, x14, 
      x24, x34, x123, x124, x134, x234, x1234
    ];
    
    // Check for proper value distribution
    propsList.forEach(x => {
      const value = x.reduce((a, b) => a + b, 0);
      if (value !== 8) {
        corruptedSplit = true;
      }
    });

    // Check for duplicates
    for (let j = 0; j < 15; j++) {
      for (let k = 0; k < 15; k++) {
        if (j !== k && JSON.stringify(propsList[j]) === JSON.stringify(propsList[k])) {
          corruptedDuplicate = true;
        }
      }
    }

    if (log) {
      console.log(
        group.map(g => g.name),
        corruptedSplit ? "Bad split!" : "",
        corruptedDuplicate ? "Duplicates!" : ""
      );
      console.log(x1, x2, x3, x4);
      console.log(x12, x13, x23, x14, x24, x34);
      console.log(x123, x124, x134, x234, x1234);
    }

    const varsList = { 
      x1, x2, x3, x4, x12, x13, x23, x14, 
      x24, x34, x123, x124, x134, x234, x1234 
    };

    // Add to result based on list type
    switch (list) {
      case contentList.all:
        basisData.push(getItemToAdd(group, dichotomies, varsList));
        break;
      case contentList.correct:
        if (!corruptedSplit && !corruptedDuplicate) {
          basisData.push(getItemToAdd(group, dichotomies, varsList));
        }
        break;
      case contentList.wrong:
        if (corruptedSplit || corruptedDuplicate) {
          basisData.push(getItemToAdd(group, dichotomies, varsList));
        }
        break;
      default:
        if (log) console.log("Invalid list type");
    }
  });

  // Store in cache before returning
  base4Cache.set(cacheKey, basisData);
  
  return basisData;
};

/**
 * Calculate basis data based on content type
 * @param {Array} dichotomies - Array of dichotomies
 * @param {String} list - Content list type
 * @param {String} type - Content type
 * @returns {Array} - Calculated basis data
 */
export const calculateBasisData = (dichotomies, list, type) => {
  return type === contentType.reinins 
    ? calculateBase4(dichotomies, list, type) 
    : calculateBase3(dichotomies, list, type);
};