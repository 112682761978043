import React from 'react';
import { contentType } from '../../Const';
import tooltip from "../../tooltips";
import { types } from "../../socioData";

const TableHeader = ({ type }) => {
  const columnWidth = "px-2";

  const FLItemTooltip = (value, style, tipType, tipParam, key) => {
    return (
      <th key={key} className={style} data-tip data-for={tipType + tipParam}>
        {value}
        {tooltip(tipType, tipParam)}
      </th>
    );
  };

  const renderAspectsHeader = () => (
    <thead>
      <tr>
        <th key="aspects-header-id" className="text-center small text-secondary">+/-</th>
        {FLItemTooltip("ЧС", columnWidth, "aspects", "name0", "aspects-0")}
        {FLItemTooltip("БС", columnWidth, "aspects", "name1", "aspects-1")}
        {FLItemTooltip("БЛ", columnWidth, "aspects", "name2", "aspects-2")}
        {FLItemTooltip("ЧЛ", columnWidth, "aspects", "name3", "aspects-3")}
        {FLItemTooltip("ЧЕ", columnWidth, "aspects", "name4", "aspects-4")}
        {FLItemTooltip("БЕ", columnWidth, "aspects", "name5", "aspects-5")}
        {FLItemTooltip("БІ", columnWidth, "aspects", "name6", "aspects-6")}
        {FLItemTooltip("ЧІ", columnWidth, "aspects", "name7", "aspects-7")}
        <th key="aspects-empty" className={columnWidth} />
      </tr>
    </thead>
  );

  const renderFunctionsHeader = () => {
    const ff = [0, 1, 2, 3, 4, 5, 6, 7];
    return (
      <thead>
        <tr>
          <th key="functions-header-id" className="text-center small text-secondary">+/-</th>
          {ff.map((f, idx) => FLItemTooltip("Ф" + (idx + 1), columnWidth, "functions", idx, `functions-${idx}`))}
          <th key="functions-empty" />
        </tr>
      </thead>
    );
  };

  const renderReininsHeader = () => (
    <thead>
      <tr>
        <th key="reinins-header-id" className="text-center small text-secondary">+/-</th>
        {types.map(t => FLItemTooltip(t.code, columnWidth, "types", t.id, `types-${t.id}`))}
        <th key="types-empty" className={columnWidth} />
      </tr>
    </thead>
  );

  if (type === contentType.reinins) {
    return renderReininsHeader();
  } else if (type === contentType.aspects) {
    return renderAspectsHeader();
  } else {
    return renderFunctionsHeader();
  }
};

export default TableHeader;