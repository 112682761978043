import React, { Suspense, useState, useEffect } from 'react';
import './App.css';
import { contentList, contentType } from './Const';
import { 
  Header, 
  Menu, 
  InfoModal, 
  Footer 
} from './components';

// Use lazy loading for page components to improve initial load performance
const SimplePage = React.lazy(() => import('./components/pages/SimplePage'));
const AdvancedPage = React.lazy(() => import('./components/pages/AdvancedPage'));

const App = () => {
  const [currentType, setCurrentType] = useState(contentType.aspects);
  const [currentList, setCurrentList] = useState(contentList.correct);
  const [showInfo, setShowInfo] = useState(false);
  const [isSimpleMode, setIsSimpleMode] = useState(true);
  const [key, setKey] = useState(0); // Add a key to force re-render when needed
  
  // Handlers for menu interactions
  const handleTypeChange = (type) => {
    setCurrentType(type);
    // Force re-render when type changes
    setKey(prevKey => prevKey + 1);
  };
  
  const handleListChange = (list) => {
    setCurrentList(list);
    // Force re-render when list changes
    setKey(prevKey => prevKey + 1);
  };
  
  const toggleSimpleMode = () => {
    setIsSimpleMode(!isSimpleMode);
    // Force re-render of the component when switching modes
    setKey(prevKey => prevKey + 1);
  };
  
  const handleInfoModalOpen = () => {
    setShowInfo(true);
  };
  
  const handleInfoModalClose = () => {
    setShowInfo(false);
  };
  
  // FallbackComponent for loading state
  const LoadingFallback = () => (
    <div className="loading-container">
      <p><b>Зачекайте трохи, дані завантажуються..</b></p>
    </div>
  );
  
  // Content to render based on mode
  const renderContent = () => {
    if (isSimpleMode) {
      return (
        <Suspense fallback={<LoadingFallback />}>
          <SimplePage key={`simple-${key}`} />
        </Suspense>
      );
    }
    
    return (
      <Suspense fallback={<LoadingFallback />}>
        {currentType && currentList ? (
          <AdvancedPage 
            key={`advanced-${currentType}-${currentList}-${key}`}
            currentType={currentType} 
            currentList={currentList}
          />
        ) : (
          <p>Виберіть розділ та тип даних</p>
        )}
      </Suspense>
    );
  };

  return (
    <div className="mainContainer">
      {/* Information Modal Component */}
      <InfoModal 
        show={showInfo}
        onClose={handleInfoModalClose}
      />
      
      {/* Header Component */}
      <Header
        onInfoClick={handleInfoModalOpen}
        isSimple={isSimpleMode}
        toggleSimple={toggleSimpleMode}
      />
      
      {/* Menu Component - Only shown in advanced mode */}
      {!isSimpleMode && (
        <Menu
          currentType={currentType}
          currentList={currentList}
          contentType={contentType}
          contentList={contentList}
          onTypeChange={handleTypeChange}
          onListChange={handleListChange}
        />
      )}
      
      {/* Main Content - Either SimplePage or AdvancedPage */}
      {renderContent()}
      
      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default App;
