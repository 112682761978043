import React from 'react';
import { Modal } from 'react-bootstrap';

const InfoModal = ({ show, onClose }) => {
    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Справка</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><i>"Лише теорія вирішує, що саме можна спостерігати."</i> - А.Ейнштейн</p>
                <p>У Соціоніку кожен Аспект і Функція, кожен Тип Інформаційного Метаболізму може бути представлений за допомогою мінімального Базису з 3 і 4 дихотомій, відповідно. Щоб бути базовими, ці дихотомії мають бути незалежними одна від одної. Як, наприклад, осі X-Y-Z для опису фізичного простору.</p>
                <p>Але, досить часто, соціоніки припускаються помилки, і використовують помилковий базис для визначення аспекту, функції, типу. Це призводило до того, що, висунута гіпотеза ТІМу, не може бути перевірена іншими дихотоміями з Ознак Рейніна, тому що не існує математично коректної комбінації з помилкового базису, яка б вказувала на обрану для перевірки дихотомію. Простіше кажучи, обрані дихотомії для складання гіпотези, та обрані для її перевірки, не є частиною однієї теорії, а знаходяться в різному просторі оцінки явищ, логічно та комбінаторно не пов'язані.</p>
                <p>
                    Існує 28 коректних та 7 помилкових базисів аспектів та функцій; 840 корективних та 525 хибних базисів ТІМу.
                </p>
                <p>На цьому сайті ви можете знайти свій улюблений метод типування, 3-4 перші критерії, які ви використовуєте для визначення гіпотези. Якщо він опиниться у списку хибних Базисів, то вам слід звернути увагу на те, що насправді обраний базис не формує повного набору дихотомічних властивостей аспекту/функції/типу, для повноцінної оцінки явища (рядки або стовпчики будуть дублюватися). І якщо ви продовжуєте його використовувати, гіпотеза аспекту/функції/типу багато в чому залежатиме від вашого упередження, бажання, а не від результату аналізу інформації соціонічними методами.</p>
                <p>На додаток, хочу звернути вашу увагу на те, що представлені варіанти базисів наочно показують нам, як аспект/функція/тип можуть бути описані десятками-сотнями математично коректними способами. Що, у свою чергу, натякає на те, що типувати можна дуже по-різному, і це нормально. У результаті, якщо предмет вивчення збігається (а це: особливості інформаційного метаболізму) суперечки у версії типу не повинно бути.
                </p>
                <p>
                    <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default InfoModal;