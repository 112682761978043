import React, { useState, useRef, useEffect } from 'react';
import '../../App.css';
import { contentType, compareNumbers } from "../../Const";
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const DrawTable = ({ data, idx, dichotomies, type, showId = true }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tableRef = useRef(null);

  // Set up intersection observer for lazy loading
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      });
    }, options);

    // Start observing the table element
    if (tableRef.current) {
      observer.observe(tableRef.current);
    }

    // Clean up the observer on unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // Generate table ID and code
  const code = [];
  code.push(data.x1 + 1);
  code.push(data.x2 + 1);
  code.push(data.x3 + 1);
  if (type === contentType.reinins) {
    code.push(data.x4 + 1);
  }
  code.sort(compareNumbers);
  
  const tableId = idx + 1;
  const itemsCodes = code.join();

  // Loading placeholder table
  const tempTable = (
    <table 
      ref={tableRef}
      className={type === contentType.reinins ? "tempTable15" : "tempTable7"}
    >
      <tbody>
        <tr>
          <td>
            <div>Loading..</div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  // Complete table with data
  const completeTable = (
    <table className="tableStyle table table-striped table-bordered" key={tableId}>
      <TableHeader type={type} />
      <TableBody data={data} dichotomies={dichotomies} type={type} />
    </table>
  );

  // ID label (optional)
  const idLabel = (
    <p>
      <b>#{tableId}</b> <span className="text-secondary">({itemsCodes})</span>
    </p>
  );

  return (
    <div className="tableContainer" key={tableId}>
      {showId ? idLabel : null}
      {!isVisible ? tempTable : completeTable}
    </div>
  );
};

export default DrawTable;