import React from 'react';
import { contentType } from '../../Const';

const TableBody = ({ data, dichotomies, type }) => {
  // Function to render error lines when data calculation fails
  const renderErrorLine = (n, k, i) => {
    const line = [];
    for (let idx = 0; idx < n; idx++) {
      line.push(<td key={idx} />);
    }
    
    return (
      <tr key={`error${k}${i}`}>
        <td><b className="redText">Помилка обчислення!</b></td>
        {line}
        <td>{k}</td>
      </tr>
    );
  };

  return (
    <tbody>
      {Object.keys(data).map((k, i) => {
        if (data[k] >= 0) {  // !== "error"
          const dName = dichotomies[data[k]].name;
          const isDuplicate = Object.keys(data)
            .map(dk => data[dk] !== undefined ? data[dk] : "error")
            .indexOf(data[k]) < i;
          
          return (
            <tr key={`line${k}${i}`}>
              <td key={`name${dName}`}>
                {(i >= (type === contentType.reinins ? 4 : 3)) ?
                  (isDuplicate ? (<i>{dName}</i>) : dName) : (<b>{dName}</b>)}
              </td>
              {dichotomies[data[k]].value.map((v, index) => (
                <td 
                  key={`cell${k}${i}${v}${index}`}
                  className="text-center"
                >
                  {v === 1 ? "+" : "-"}
                </td>
              ))}
              <td>{k}</td>
            </tr>
          );
        } else {
          return renderErrorLine(type === contentType.reinins ? 16 : 8, k, i);
        }
      })}
    </tbody>
  );
};

export default TableBody;