/**
 * Utility functions for filtering data
 */

/**
 * Checks if a data item matches the filter criteria
 * @param {Object} data - The data object to check
 * @param {Array} filters - Array of filter indices
 * @param {Number} amount - Number of items to check
 * @returns {Boolean} - Whether the data matches the filters
 */
export const filterConditionCheck = (data, filters, amount) => {
  if (filters.length === 0) {
    return true; // If no filters are applied, return all data
  }
  
  let found = 0;
  const arr = Object.keys(data)
    .map(k => data[k] + "")
    .slice(0, amount);

  filters.forEach((key) => {
    if (arr.includes(key)) {
      found++;
    }
  });
  
  return (found === filters.length);
};

/**
 * Filters an array of data based on filter criteria
 * @param {Array} dataArray - Array of data objects to filter
 * @param {Array} filters - Array of filter indices
 * @param {Boolean} isReinins - Whether the data is reinins type
 * @returns {Array} - Filtered data array
 */
export const filterDataArray = (dataArray, filters, isReinins) => {
  if (filters.length === 0) {
    return dataArray; // Return all data if no filters
  }
  
  return dataArray.filter(data => 
    filterConditionCheck(data, filters, isReinins ? 4 : 3)
  );
};