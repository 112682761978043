import React, { useState, useEffect, Suspense, lazy, useCallback, useMemo } from 'react';
import '../../App.css';
import { contentType } from '../../Const';
import { filterDataArray } from '../filters';
import { aspects, functions, reinins } from '../../socioData';

// Use lazy loading for heavy components with proper import syntax
const FilterManager = lazy(() => import('../filters/FilterManager'));
const DataManager = lazy(() => import('../calculations/DataManager'));
const DrawTable = lazy(() => import('../tables/DrawTable'));

// Loading component
const LoadingComponent = () => (
  <div className="component-loading">
    <p>Завантаження...</p>
  </div>
);

const AdvancedPage = ({ currentType, currentList }) => {
  const [basisData, setBasisData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  // Get appropriate dichotomy data based on content type
  const getDichotomies = () => {
    return currentType === contentType.reinins 
      ? reinins 
      : currentType === contentType.aspects 
        ? aspects 
        : functions;
  };

  // Memoize dichotomies to prevent unnecessary recalculations
  const dichotomies = useMemo(() => getDichotomies(), [currentType]);

  // Update filtered data when basis data or filters change
  useEffect(() => {
    const isReinins = currentType === contentType.reinins;
    setFilteredData(filterDataArray(basisData, activeFilters, isReinins));
  }, [basisData, activeFilters, currentType]);

  // Handle filter changes - memoize to maintain stable identity
  const handleFilterChange = useCallback((filters) => {
    setActiveFilters(filters);
  }, []);

  // Handle data loading completion - memoize to maintain stable identity
  const handleDataLoaded = useCallback((data) => {
    setBasisData(data);
  }, []);

  // Content header based on type and list
  const renderInfoHeader = () => {
    const typeLine = currentType === contentType.reinins 
      ? "" 
      : currentType === contentType.aspects 
        ? "Аспектів" 
        : "Функцій";
    
    let listLine = "";
    switch (currentList) {
      case "correct":
        listLine = "Математично коректні варіанти";
        break;
      case "wrong":
        listLine = "Математично хибні варіанти";
        break;
      default:
        listLine = "Всі варіанти";
    }

    return (
      <div className="caption">
        {currentType === contentType.reinins ? (
          <h5>{listLine} Базису типу за Ознаками Рейніна.</h5>
        ) : (
          <h5>{listLine} Базису ознак {typeLine}.</h5>
        )}
      </div>
    );
  };

  // Render legends based on content type
  const renderLegend = () => {
    switch (currentType) {
      case contentType.reinins:
        return (
          <p>ІЛЕ - Шукач; СЕІ - Посередник; ЕСЕ - Ентузіаст; ЛІІ - Аналітик; ЕІЕ - Наставник; ЛСІ - Інспектор; СЛЕ - Маршал; ІЕІ - Лірик; СЕЕ - Політик; ІЛІ - Критик; ЛІЕ - Підприємець; ЕСІ - Хранитель; ЛСЕ - Адміністратор; ЕІІ - Гуманіст; ІЕЕ - Порадник; СЛІ - Майстер</p>
        );
      case contentType.aspects:
        return (
          <p>ЧС - Вольова Сенсорика; БС – Сенсорика Комфорту; БЛ – Структурна Логіка; ЧЛ – Ділова Логіка; ЧЕ – Етика Емоцій; БЕ – Етика Відносин; БІ – Інтуїція Часу; ЧІ - Інтуїція Можливостей</p>
        );
      case contentType.functions:
        return (
          <p>1 – Базова; 2 – Творча; 3 – Рольова; 4 – Больова; 5 - Навіювана; 6 – Активаційна; 7 – Обмежувальна; 8 - Фонова</p>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Suspense fallback={<LoadingComponent />}>
        <DataManager 
          currentType={currentType}
          currentList={currentList}
          dichotomies={dichotomies}
          onDataLoaded={handleDataLoaded}
        />
      </Suspense>

      {renderInfoHeader()}
      
      <Suspense fallback={<LoadingComponent />}>
        <FilterManager 
          dichotomies={dichotomies}
          onFilterChange={handleFilterChange}
        />
      </Suspense>

      <div className="allTablesContainer d-flex flex-wrap">
        {filteredData.map((data, idx) => (
          <Suspense key={idx} fallback={<LoadingComponent />}>
            <DrawTable 
              data={data}
              idx={idx}
              dichotomies={dichotomies}
              type={currentType}
            />
          </Suspense>
        ))}
      </div>

      {renderLegend()}
    </div>
  );
};

export default AdvancedPage;