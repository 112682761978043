import React from 'react';
import { QuestionCircleIcon } from './Icons';

const Header = ({ onInfoClick, isSimple, toggleSimple }) => {
    return (
        <div className="d-flex align-items-center">
            <h3>Соціоніка: Базис</h3>
            <a href="#" className="mx-3 mb-1" onClick={onInfoClick}>
                <QuestionCircleIcon />
            </a>
            <form className="mx-3">
                <input type="checkbox" defaultChecked={!isSimple} onChange={toggleSimple} />
                <label className="small mx-1">Розширено</label>
            </form>
        </div>
    );
};

export default Header;