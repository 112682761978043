import React, { useState } from 'react';
import FilterModal from './FilterModal';
import FilterControls from './FilterControls';

const FilterManager = ({ dichotomies, onFilterChange }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const handleCloseModal = () => {
    setShowFilters(false);
    setSelectedFilters([]);
  };

  const handleApplyModal = (e) => {
    e.preventDefault();
    setShowFilters(false);
    setActiveFilters(selectedFilters);
    onFilterChange(selectedFilters);
  };

  const handleSelect = (e) => {
    const key = e.target.id;
    let selected = [...selectedFilters];

    if (selected.includes(key)) {
      selected = selected.filter(i => i !== key);
    } else {
      selected.push(key);
    }

    setSelectedFilters(selected);
  };

  const handleShowFilters = () => {
    setSelectedFilters(activeFilters);
    setShowFilters(true);
  };

  const handleClearFilters = () => {
    setSelectedFilters([]);
    setActiveFilters([]);
    onFilterChange([]);
  };

  return (
    <>
      <FilterModal
        show={showFilters}
        selectedFilters={selectedFilters}
        onClose={handleCloseModal}
        onApply={handleApplyModal}
        onSelect={handleSelect}
        dichotomies={dichotomies}
      />
      <FilterControls
        activeFilters={activeFilters}
        onShowFilters={handleShowFilters}
        onClearFilters={handleClearFilters}
        dichotomies={dichotomies}
      />
    </>
  );
};

export default FilterManager;