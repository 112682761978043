import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p>(c) <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>, 2021</p>
        </div>
    );
};

export default Footer;