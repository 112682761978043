import React, { useState, useEffect, useRef } from 'react';
import { contentType, log } from '../../Const';
import { calculateBasisData } from './CalculationUtils';

/**
 * Component to manage data loading and caching for basis calculations
 */
const DataManager = ({ currentType, currentList, dichotomies, onDataLoaded }) => {
  const [isLoading, setIsLoading] = useState(false); // Start with false
  const cacheKeyRef = useRef(`${currentType}${currentList}`);
  const loadingTimerRef = useRef(null);

  // Cleanup function to clear any timers
  const cleanup = () => {
    if (loadingTimerRef.current) {
      clearTimeout(loadingTimerRef.current);
      loadingTimerRef.current = null;
    }
  };

  // When props change, update the cache key
  useEffect(() => {
    cacheKeyRef.current = `${currentType}${currentList}`;
    return cleanup;
  }, [currentType, currentList]);

  // Load data effect
  useEffect(() => {
    if (!currentType || !currentList || !dichotomies || !onDataLoaded) {
      return; // Skip if any required props are missing
    }

    cleanup(); // Clear previous timers
    
    const loadData = async () => {
      const cacheKey = cacheKeyRef.current;
      
      try {
        // First check localStorage for cached data
        const cachedData = window.localStorage.getItem(cacheKey);
        
        if (cachedData) {
          if (log) console.log("Found data in cache:", cacheKey);
          const parsedData = JSON.parse(cachedData);
          onDataLoaded(parsedData);
          setIsLoading(false); // Ensure loading is false when using cached data
        } else {
          // Show loading indicator
          setIsLoading(true);
          
          if (log) console.log("Calculating data for:", cacheKey);
          
          // Use a timer to allow the loading state to render
          loadingTimerRef.current = setTimeout(() => {
            try {
              const calculatedData = calculateBasisData(dichotomies, currentList, currentType);
              
              // Save to localStorage cache
              try {
                window.localStorage.setItem(cacheKey, JSON.stringify(calculatedData));
              } catch (storageError) {
                console.warn("Failed to cache data in localStorage:", storageError);
              }
              
              onDataLoaded(calculatedData);
              
              // Add a small delay before hiding the loading indicator to ensure rendering completes
              loadingTimerRef.current = setTimeout(() => {
                setIsLoading(false);
              }, 300);
              
            } catch (calcError) {
              console.error("Calculation error:", calcError);
              setIsLoading(false);
            }
          }, 50);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        setIsLoading(false);
      }
    };
    
    loadData();
    
    return cleanup;
  }, [currentType, currentList, dichotomies, onDataLoaded]);

  // Component cleanup on unmount
  useEffect(() => {
    return cleanup;
  }, []);

  // Only render the loading indicator if isLoading is true
  return isLoading ? (
    <div className="text-center my-4">
      <div className="loading-indicator">
        <p><b>Зачекайте трохи, дані завантажуються..</b></p>
        <div className="spinner"></div>
      </div>
    </div>
  ) : null;
};

export default DataManager;