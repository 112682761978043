import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const FilterModal = ({ show, selectedFilters, onClose, onApply, onSelect, dichotomies }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Фільтри Базису</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dichotomies.length === 7 ? (
          <p>Виберіть від 1 до 3:</p>
        ) : (
          <p>Виберіть від 1 до 4:</p>
        )}
        <Form>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            {dichotomies.map((d, idx) => (
              <Form.Check
                key={idx}
                type="checkbox"
                id={`${idx}`}
                checked={selectedFilters.includes(`${idx}`)}
                label={d.name}
                onChange={onSelect}
              />
            ))}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onApply}>
          Застосувати
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;