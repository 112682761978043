import React from 'react';
import { Button } from 'react-bootstrap';

const Menu = ({ currentType, currentList, contentType, contentList, onTypeChange, onListChange }) => {
    return (
        <div className="my-2">
            <Button 
                onClick={() => onTypeChange(contentType.aspects)} 
                className="me-2" 
                variant={currentType === contentType.aspects ? "primary" : "outline-primary"}
            >
                Аспекти
            </Button>
            <Button 
                onClick={() => onTypeChange(contentType.functions)} 
                className="me-2" 
                variant={currentType === contentType.functions ? "primary" : "outline-primary"}
            >
                Функції
            </Button>
            <Button 
                onClick={() => onTypeChange(contentType.reinins)} 
                className="me-4" 
                variant={currentType === contentType.reinins ? "primary" : "outline-primary"}
            >
                Ознаки Рейніна
            </Button>
            <Button 
                onClick={() => onListChange(contentList.correct)} 
                className="me-2" 
                variant={currentList === contentList.correct ? "secondary" : "outline-secondary"}
            >
                Коректні
            </Button>
            <Button 
                onClick={() => onListChange(contentList.wrong)} 
                className="me-2" 
                variant={currentList === contentList.wrong ? "secondary" : "outline-secondary"}
            >
                Помилкові
            </Button>
            <Button 
                onClick={() => onListChange(contentList.all)} 
                className="me-4" 
                variant={currentList === contentList.all ? "secondary" : "outline-secondary"}
            >
                Усі
            </Button>
        </div>
    );
};

export default Menu;