import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * FilterControls component displays active filters and provides controls
 * for showing the filter modal and clearing filters
 */
const FilterControls = ({ activeFilters, onShowFilters, onClearFilters, dichotomies }) => {
  // Format filters for display - map filter ids to filter names
  const filterDisplay = activeFilters
    .map(f => (f <= dichotomies.length) ? dichotomies[f].name : "--")
    .join(", ");

  return (
    <div className="d-flex">
      <span className="p-1">Фільтри:</span>
      <span className="p-1">
        <b>{filterDisplay}</b>
      </span>
      <Button 
        variant="light" 
        className="mx-2 btn-sm"
        onClick={onShowFilters}
      >
        {activeFilters.length > 0 ? "Змінити" : "Додати"}
      </Button>
      <Button 
        variant="light" 
        className="btn-sm" 
        onClick={onClearFilters}
      >
        Видалити
      </Button>
    </div>
  );
};

export default FilterControls;